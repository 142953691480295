<script setup lang="ts">
import { FormKitLazyProvider } from '@formkit/vue'
import { reset } from '@formkit/core'

definePageMeta({
    title: 'Contact Storm',
    description: 'Contact Storm for all your needs',
    layout: 'default-no-contact'
})

useHead({
    title: 'STORM - Contact',
})

const selectedLanguage = ref('en')
const languages = ['en', 'de', 'fr']

const token = ref()
const turnstile = ref()

const contactForm = ref(null)
const tokenField = ref()
const formVisible = ref(true)
const formIsLoading = ref(false)

const token$ = ref<any>(null);

onMounted(() => {
})

const resetTurnstile = () => {
    turnstile.value.reset()
}

const turnstileCallback = (token: string) => {
}

const successHandler = (response: any, form: any) => {
    formVisible.value = false
    reset('contactForm', {
        name: '',
        email: '',
        message: '',
        token: ''
    })
}

const submitHandler = async (data: any, node: FormKitNode) => {
    formIsLoading.value = true
    try {
        if (!token.value) {
            console.error('Token not set')
            return
        }
        data.token = token.value
        const response = await $fetch('/api/contact/', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        successHandler(response, contactForm)
    } catch (error) {
        console.error(error)
    }
    formIsLoading.value = false
}

watch(token, (value, oldValue) => {
    if (value) {
        token$.value = value
    }
})

</script>

<template>
    <div class="relative bg-[#f3ebdeff] text-black w-full flex flex-col items-center justify-center">
        
<FormKitLazyProvider config-file="true">
<HeaderImage title="About" image="/images/contact-us.webp" :show-link="true" />
        <div class="flex items-center justify-center w-full px-10 ">
            <div class="xl:container grid grid-cols-1 grid-flow-row gap-4">
                <div class="w-full mt-1">
                    <BreadCrumbs />
                </div>
                <div class="mt-16 text-lg mb-40">
                    <div class="w-full flex flex-col items-top items-center justify-center">
                        <div class="grid grid-cols-1 w-full md:grid-cols-2 gap-10 items-top justify-center">
                            <div class="pr-20">
                                <h1 class="font-['ProximaNova'] text-4xl sm:text-6xl lg:text-7xl font-bold text-nowrap">Contact Us</h1>
                                <p class="mt-14">At STORM Defence, we are committed to supporting your defence organization with tailor-made security solutions that address your
                                    unique needs.
                                    Please fill out the form below with the necessary information, and we will promptly get in touch with you to initiate a discussion on how we can
                                    collaboratively find the optimal solution that meets your requirements and expectations in the best possible way. Thank you for considering
                                    STORM Defence as your security partner!
                                </p>
                                <div class="grid grid-cols-2 gap-4 mt-10">
                                    <div class="grid grid-cols-1 gap-5 text-nowrap">
                                        <h2 class="text-lg font-bold">Address</h2>
                                        <div>
                                            <NuxtLink
                                                to="https://www.google.com/maps/place/Peak+Polar+AS/@59.2521181,10.2638226,17.68z/data=!4m15!1m8!3m7!1s0x4646c7e7161dd2fb:0x68e3690919b7fbb0!2sBorgeskogen,+3160+Stokke!3b1!8m2!3d59.2464992!4d10.2587539!16s%2Fg%2F1tgfv6nn!3m5!1s0x4646c721ffcf2461:0xe2f7a67092594b19!8m2!3d59.2518119!4d10.2640441!16s%2Fg%2F11v5dkmfnt?entry=ttu"
                                                target="_blank">
                                                <Icon name="fa6-solid:location-dot" class="w-6 h-6" color="#61ce70" />
                                                &nbsp;
                                                <span>Stokke, Norway</span>
                                            </NuxtLink>
                                        </div>
                                        <div>
                                            <NuxtLink to="mailto:post@stormdefgov.com" target="_blank">
                                                <Icon name="fa-regular:envelope" class="w-6 h-6" color="#61ce70" />
                                                &nbsp;
                                                <span>post@stormdefgov.com</span>
                                            </NuxtLink>
                                        </div>
                                        <div>
                                            <NuxtLink to="tel:+4799556620" target="_blank">
                                                <Icon name="fa-solid:phone" class="w-6 h-6" color="#61ce70" />
                                                &nbsp;
                                                <span>+47 995 56 620</span>
                                            </NuxtLink>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="w-full">
                                <div class="w-full" v-if="formVisible">

                                    <FormKit ref="contactForm" type="form" id="contactForm" :form-class="formVisible ? 'show' : 'hide'" :disabled="formIsLoading" submit-label="Send" @submit="submitHandler"
                                        :actions="false" #default="{ state: { valid }, value }" :config="{
                                            // config override applies to all nested FormKit components
                                            classes: {
                                                outer: 'mb-5',
                                                label: 'block mb-1 font-bold text-sm',
                                                inner: 'border border-gray-400 rounded-md overflow-hidden focus-within:border-green-700 focus-within:ring-2 focus-within:ring-green-700',
                                                input: 'w-full px-3 border-none text-base text-gray-700 placeholder-gray-400 disabled:opacity-50 disabled:cursor-not-allowed',
                                                message: 'mt-2 ml-3 text-sm text-red-500',
                                                help: 'text-xs text-gray-500'
                                            },
                                        }">

                                        <div class="w-full grid grid-cols-2 gap-4">
                                            <div class="">
                                                <FormKit ref="" type="text" name="name" label="Name" placeholder="Full name" validation="required" />
                                            </div>
                                            <div class="">
                                                <FormKit type="text" name="email" label="E-mail" placeholder="E-mail address" validation="required|email" />
                                            </div>
                                            <div class="col-span-2">
                                                <FormKit name="message" type="textarea" outer-class="w-full" rows="10" label="Message" placeholder="Write your message here"
                                                    validation="required|length:5" />
                                            </div>
                                            <div class="col-span-2 pb-5">
                                                <FormKit ref="tokenField" type="hidden" name="token" v-model="token" validation="required" :validation-messages="{
                                                    required: 'Please verify that you are a human.',
                                                }" />
                                                <NuxtTurnstile :key="selectedLanguage" v-model="token" ref="turnstile"
                                                    :options="{ action: 'native', theme: 'light', language: selectedLanguage }" element="token" />
                                                <FormKitMessages :node="tokenField?.node" class="mt-2"/>
                                            </div>
                                            <div class="col-span-2">
                                                <FormKit type="submit" label="Send" :disabled="formIsLoading" :classes="{
                                                    outer: 'mb-0',
                                                    input: 'bg-green-900 hover:bg-green-700 text-white font-bold py-2 px-3 rounded-md w-auto disabled:cursor-not-allowed disabled:opacity-50',
                                                }" />
                                            </div>
                                        </div>

                                        <!-- <pre wrap>{{ value }}</pre> -->
                                    </FormKit>

                                </div>
                                <div v-else class="flex flex-col items-center justify-center pt-28">
                                    <p>Thank you for contacting us. We will get back to you as soon as possible.</p>
                                    <button type="button" @click="formVisible = !formVisible"
                                        class="mt-10 text-white bg-green-900 hover:bg-green-700 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2">
                                        Contact us again
                                    </button>
                                </div>
                                <!-- <div>{{ formData }}</div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</FormKitLazyProvider>

    </div>
</template>

<style>
textarea:focus {
    outline: none !important;
}
</style>
